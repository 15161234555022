<template>
  <div class="form-wrap">
    <form class="login">
      <p class="login-register">
        <!--Register the account panel in Login section-->
        Haven't Got an Account?
        <router-link class="router-link" :to="{name:'Register'}">Register</router-link>
      </p>
        <!--Form content section-->
      <h2>Login to My Blog</h2>
      <div class="inputs">

        <div class="input">
          <input type="text" placeholder="Email" v-model="email" />
          <email class='icon'/>
        </div>

        <div class="input">
          <input type="text" placeholder="Password" v-model="password" />
          <password class='icon'/>
        </div>
     <!--show the error message returned from the server-->
              <div v-show='error' class="error">{{this.errorMsg}}</div>

      </div>
      <!--router link section to forgot password view-->
      <router-link class='forgot-password' :to="{name : 'ForgotPassword'}">Forgot your Password?</router-link>

<!--use @click.prevent to prevent the whole page refresh when the page request for the method-->
      <button @click.prevent="signIn">Sign in</button>

      <div class="angle"></div><!--design section, no content, used for styling-->
    </form>
     <div class="background"></div><!--design section, no content, used for styling-->
  </div>
</template>

<script>
import email from "../assets/Icons/envelope-regular.svg";
import password from "../assets/Icons/lock-alt-solid.svg";
//import firebase
import firebase from "firebase/app"
import "firebase/auth"

export default {
  name: "Login",
  components:{
    email,
    password,
  },

  data(){
    return {
      email: "",
      password: "",
      error: null,
      errorMsg:"",
    };
  },

  methods: {
    ////////////////////////////Firebase Method section///////////////////////////
    //sign in with the provided email and password
    signIn(){
      firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(()=>{
        this.$router.push({name:"Home"});
        this.error = false;
        this.errorMsg = "";
        console.log(firebase.auth().currentUser);
      })
      //catch the connection error
      .catch((err) =>{
        this.error = true;
        this.errorMsg = err.message;
      });
    }
              //////////////////////////////////////////////////////////
  }
}
</script>


<!--This stylesheet is not scooped because it is sharable to Fogotpsw and Register view-->
<style lang="scss">
  .form-wrap{
    overflow: hidden;
    display: flex;
    height: 100vh;
    justify-content: center;
    align-self: center;
    margin: 0 auto;
    width: 90%;
    @media(min-width: 900px){
      width: 100%;
    }

    .login-register{
      margin-bottom: 32px;

      .router-link{
        color: #000;
      }
    }

    form{
      padding: 0 10px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1;
      @media(min-width: 900px){
        padding: 0 50px;
      }

      h2 {
        text-align: center;
        font-size: 32px;
        color: #303030;
        margin-bottom: 30px;
        @media(min-width: 900px){
          font-size: 30px;
        }
      }

      .inputs{
        width: 100%;
        max-width: 350px;

        .input{
          position:relative;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 8px;
          
          input{
            width: 100%;
            border: none;
            background-color: #f2f7f6;
            padding: 4px 4px 4px 30px;
            height: 50px;

            &:focus{
              outline: none;
            }
          }

          .icon{
            width: 12px;
            position:absolute;
            left: 6px;
          }
        }
      }

      .forgot-password{
        text-decoration: none;
        color: #000;
        cursor: pointer;
        font-size: 14px;
        margin: 16px 0 32px;
        border-bottom: 1 solid transparent;
        transition: .5s ease all;

        &:hover{
          border-color: #303030;
        }
      }
      
      .angle{
        display: none;
        position: absolute;
        background-color: #fff;
        transform: rotateZ(3deg);
        width: 60px;
        right: -30px;
        height: 101%;
        @media(min-width: 900px){
          display: initial;
        }
      }
    }

    .background{
      display: none;
      flex: 2;
      background-size: cover;
      background-image: url("../assets/background.png");
      width: 100%;
      height: 100%;
      @media(min-width: 900px){
        display: initial;
      }
    }
  }
</style>