import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vue2Editor from "vue2-editor";
//import firebase
import firebase from "firebase/app"
import "firebase/auth"

Vue.use(Vue2Editor);
//close the production tips, it will bring too many red underline, which will be eaisly eased by VScode plugin
Vue.config.productionTip = false;

let app;
firebase.auth().onAuthStateChanged(
  ()=>{
    if(!app){
      new Vue({
        router,
        store,
        render: (h) => h(App),
      }).$mount("#app");
    }
  });

