<template>
  <footer>
      <div class="container">
          <div class="left">
              <div class="col-1">
                  <router-link class="header" :to="{name:'Home'}">Han's Blog</router-link>
                    <!--Social Media Panel-->
              <ul>
                  <li><a href="https://www.facebook.com/HuaiqingHan2022"><facebook class='svg-icon'/></a></li>
                  <li><a href="https://www.instagram.com/aldrich.han/"><instagram class='svg-icon'/></a></li>
                  <li><a href="https://www.linkedin.com/in/huaiqinghan-7015/"><linkedin class='svg-icon'/></a></li>
              </ul>

              </div>

                <div class="col-2">
                    <!--Footer Menu Panel-->
                    <ul>
                        <router-link class="link" :to="{ name: 'Home' }">Home</router-link>
                        <router-link class="link" :to="{ name: 'Blogs' }">My Blog</router-link>
                        <!--add a administration level, only editor can write a post-->
                        <router-link  v-if="admin" class="link" :to="{ name: 'CreatePost' }">Write New Post</router-link>
                        <router-link  v-if="!user" class="link" :to="{name: 'Login' }">Login/Register</router-link>
                    </ul>
                </div>
            </div>

          <div class="right">
              <p>© Copyright 2022 All Rights Reserved</p>
          </div>

    </div>
  </footer>
</template>

<script>
import facebook from "../assets/Icons/facebook-square-brands.svg";
import instagram from "../assets/Icons/instagram-brands.svg";
import linkedin from "../assets/Icons/linkedin-brands.svg";

//import and register the svgs which will be used in the HTML section

export default {
    name:"footer-vue",
    components:{
        facebook,
        instagram,
        linkedin,
    },
    computed:{
    //get the state of whether the page has a user
        user(){
            return this.$store.state.user;
        },
    //get the state of the admin
        admin(){
            return this.$store.state.profileAdmin;
        }
    }
};
</script>

<style lang="scss" scoped>

    footer{
        margin-top: auto;
        padding: 100px 25px;
        background-color: #303030;

        .container{
            display: flex;
            flex-direction: column;
            gap:32px;
            @media(min-width: 800px){
                flex-direction: column;
                gap: 0px;
            }

            > div{
                display: flex;
                flex:1;
            }

            .left{
                gap:32px;
                color: #fff;
                display: flex;
                flex-direction: column;
                align-items: center;
                @media (min-width: 800px){
                    flex-direction: row;
                    align-items: initial;
                    gap: 0;
                }

                .header{
                text-align: center;
                font-size: 24px;
                color: #fff;
                text-decoration: none;
                font-weight: 600;
                 @media (min-width: 800px) {
                        text-align: initial;
                    }
                }

                ul {
                gap: 16px;
                list-style: none;
                display: flex;
                }

                .col-1,
                .col-2 {
                gap: 32px;
                display: flex;
                flex: 1;
                @media (min-width: 800px) {
                    gap: 0;
                }
                }

                .col-1{
                flex-direction: column;

                h2 {
                    text-align: center;
                    @media (min-width: 800px) {
                        text-align: initial;
                    }
                }

                ul{
                    margin-top: auto;

                    li{
                        display: flex;
                        align-items: center;
                        .svg-icon {
                            width: 24px;
                            height: auto;
                            color: #fff;
                        }
                    }
                    }
                }
            
                .col-2{
                    ul{
                        height: 100%;
                        justify-content: center;
                        flex-direction: row;
                        flex-wrap: wrap;
                        @media (min-width: 800px){
                            flex-direction: column;
                        }

                    .link{
                        font-size: 16px;
                        font-weight: 500;
                        color: #fff;
                        text-decoration: none;
                        }
                        }
                    }
                }

                .right{
                gap: 32px;
                color: #fff;
                align-items: center;
                flex-direction: column;
                @media (min-width: 800px) {
                    align-items: flex-end;
                    gap: 0;
                }
                }

                p{
                margin-top: auto;
                 }
        }
    }
</style>