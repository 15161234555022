<template>
<!--the home section-->
  <div class="home">
    <!--Main parts and welcome of the blog-->
    <BlogPost :post="welcomeScreen"/>
    <BlogPost :post="post" v-for="(post, index) in blogPostsFeed" :key="index"/>
    <!--blog card section-->
    <div class="blog-card-wrap">
      <div class="container">
        <h3>View My Recent Blogs</h3>
        <div class="blog-cards">
          <BlogCard :post="post" v-for="(post, index) in blogPostsCards" :key="index"/>
        </div>
      </div>
    </div>
    <!--Register section, it will lead user to register/login view-->
    <div v-if="!user" class="updates">
      <div class="container">
        <h2>Register for your account to follow my latest post</h2>
        <router-link class='router-button' :to="{ name: 'Login' }">
          Register for Han's Blog <Arrow class="arrow arrow-light" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import BlogPost from '../components/BlogPost.vue';
import BlogCard from '../components/BlogCard.vue';
import Arrow from "../assets/Icons/arrow-right-light.svg";
//import the Svgs

export default {
  name: "Home",
  components: { BlogPost, BlogCard, Arrow },
  data(){
    return{
      //The first sector of the Home page will be WelcomeScreen
      welcomeScreen:{
        title:"Welcome to Han's Post!",
        blogPost:" I will share and record my coding experience and study material in this blog. Front end study recording Function mainly. Also, some assignment tricks will be provided in my posts!",
        welcomeScreen: true,
        photo:"coding",
      },
      //Rest posts will be shown in sequence below
      // sampleBlogPost:[
      //   {
      //     title: "This is a Filler Title",
      //     blogHTML: "This is a filler blog post title!",
      //     blogCoverPhoto:"beautiful-stories",
      //   },

      //   {
      //     title: "This is a Filler Title",
      //     blogHTML: "This is a filler blog post title!",
      //     blogCoverPhoto:"designed-for-everyone",
      //   },
      // ],

    };
  },
  //section for computed JavaScript function
  computed:{
    // //show the sample Blog cards and recall the latest state
    // sampleBlogCards(){
    //   return this.$store.state.sampleBlogCards
    // },

    blogPostsCards(){
      return this.$store.getters.blogPostsCards;
    },

    blogPostsFeed(){
      return this.$store.getters.blogPostsFeed;
    },

    //get the state of whether the page has a user
    user(){
          return this.$store.state.user;
        }
  }
};
</script>

<style lang="scss" scoped>
    .blog-card-wrap{
      h3{
        font-weight: 300;
        font-size: 28px;
        margin-bottom: 32px;
      }
    }
    .updates{
      .container{
        padding: 100px 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        @media(min-width: 800px){
          padding: 125px 25px;
          flex-direction: row;
        }

        .router-botton{
          display: flex;
          font-size: 14px;
          text-decoration: none;
          @media (min-width:800px) {
            margin-left: auto;
          }
        }

        h2{
          font-weight: 300;
          font-size: 32px;
          max-width: 425px;
          width: 100%;
          text-align: center;
          text-transform: uppercase;
          @media(min-width: 800px){
            text-align: initial;
            font-size: 40px;
          }
        }

        }
    }
</style>
