<template>
    <!--this is the no-user condition for performance testing-->
  <div class="blog-wrapper" :class="{ 'no-user' : !user }">

      <div class="blog-content">
          <div>
              <!--if the value is true in home section, show the welcome title-->
              <h2 v-if="post.welcomeScreen">{{post.title}}</h2>
              <h2 v-else>{{post.blogTitle}}</h2>
              <!--else, when false, page will show the post title-->
              <p v-if="post.welcomeScreen">{{post.blogPost}}</p>

              <!--the if-else conditon is the same as the post title condition-->
              <p class="content-preview" v-else v-html="post.blogHTML"></p>
                <router-link class="link link-light" v-if="post.welcomeScreen" to="#">
                
                <!--light arrow for the darker backgroun, while the solid arrow for the light background-->
                  Login/Register<Arrow class="arrow arrow-light" />
                </router-link>
                <router-link class="link" v-else :to="{name: 'ViewBlog', params:{ blogid: this.post.blogID } }">
                  View The Post<Arrow class="arrow" />
                </router-link>

          </div>
      </div>
      <!--import the archive photo for the blog post, currently the photo is based on assests photo-->
    <div class="blog-photo">
        <img v-if="post.welcomeScreen" :src="require(`../assets/blogPhotos/${post.photo}.jpg`)" alt="">
        <img v-else :src="post.blogCoverPhoto" alt="">
    </div>

  </div>

</template>

<script>
//import and register the arrow svg for the html section
import Arrow from "../assets/Icons/arrow-right-light.svg";
export default {
    name:"blogPost",
    props: ["post"],
    components:{
        Arrow,
    },

    computed:{
    //get the state of whether the page has a user
        user(){
            return this.$store.state.user;
        }
    }
}
</script>

<style lang="scss" scoped>
    .blog-wrapper{
        display: flex;
        flex-direction: column;
        box-shadow: 0 4px 6px -1px rgba($color: #000000, $alpha: 1.0), 0 2px 4px -1px rgba($color: #000000, $alpha: 0.06);
        @media(min-width: 700px){
            min-height: 650px;
            max-height: 650px;
            flex-direction: row;
        }

        .blog-content{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex: 4;
            order: 2;
            @media(min-width: 700px){
                order:1;
            }
            @media(min-width: 800px){
                flex:3;
            }

            div {
                max-width: 375px;
                padding: 72px 24px;
                @media (min-width:700px) {
                    padding: 0 24px;
                }
            }

            h2 {
                font-size: 32px;
                font-weight: 300;
                text-transform: uppercase;
                margin-bottom: 24px;
                @media(min-width: 700px){
                    font-size: 40px;
                }
            }

            p {
                font-size: 15px;
                font-weight: 300;
                line-height: 1.7;
            }

            .content-preview {
                font-size: 13px;
                max-height: 24px;
                white-space: nowrap;
                overflow: hidden;
                width:250px;
                text-overflow: ellipsis;
            }

            .link{
                display: inline-flex;
                align-items: center;
                margin-top: 32px;
                padding-bottom:4px;
                border-bottom: 1px solid transparent;
                transition: .5s ease-in all;

                &:hover{
                    border-bottom-color: #303030;
                }
            }
            
            .link-light{
                &:hover{
                    border-bottom-color: #ffff;
                }
            }
        }

        .blog-photo{
            order:1;
            flex: 3;
            box-shadow: 0 4px 6px -1px rgba($color: #000000, $alpha: 1.0), 0 2px 4px -1px rgba($color: #000000, $alpha: 0.06);
           //change the order for blog photo when reducing the screen width, layer will be on the first order when 700px
           @media(min-width: 700px){
               order:2
           }
           @media(min-width: 800px){
               flex: 4;
           }
            img{
                display: block;
                width: 100%;
                height: 100%;
            }
        }
        //for even number, the photo will be shown on the left side. Opposite when the column is double number index
            &:nth-child(even){
                .blog-content{
                    order:2
                }
                .blog-photo{
                    order: 1;
                }   
            }
            
            .arrow{
                margin-left: 8px;
                width: 12px;
                path {
                    fill: #000000;
                }
            }

            .arrow-light{
                path {
                    fill:#ffff;
                }
            }
    }
// this part is just for testing how the no user condition performance
    .no-user:first-child{
        .blog-content{
            background-color: #303030;
            color:#fff;
        }
    }
</style>