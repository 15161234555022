///////////////////////
/////////////////////////
/////register the different view model in route\\\\\\\\\\\\\
//////////////////////////////
//////////////////////////////////

import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Blogs from "../views/Blogs.vue"
import Login from "../views/Login.vue"
import Register from "../views/Register.vue"
import ForgotPassword from "../views/ForgotPassword.vue"
import Profile from "../views/Profile.vue"
import Admin from "../views/Admin.vue"
import CreatePost from "../views/CreatePost.vue"
import BlogPreview from "../views/BlogPreview.vue"
import ViewBlog from "../views/ViewBlog.vue"
import EditBlog from "../views/EditBlog.vue"
import firebase from 'firebase/app'
import "firebase/auth";

Vue.use(VueRouter);

const routes = [
/////////////////Home route
  {
    path: "/",
    name: "Home",
    component: Home,
    //show the meta data tag
    meta:{
      title:'Home',
      requiresAuth: false,
    }
  },

/////////////////blog route
  {
    path: "/blogs",
    name: "Blogs",
    component: Blogs,
    //show the meta data tag
    meta:{
      title:'Blogs',
      requiresAuth: false,
    }
  },
  
////////////////Login Route
  {
    path: "/Login",
    name: "Login",
    component: Login,
    //show the meta data tag
    meta:{
      title:'Login',
      requiresAuth: false,
    }
  },

//////////////////Register Route
  {
    path: "/Register",
    name: "Register",
    component: Register,
    //show the meta data tag
    meta:{
      title:'Register',
      requiresAuth: false,
    }
  },

///////////////Fogot Password Route
  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: ForgotPassword,
    //show the meta data tag
    meta:{
      title:'Forgot Password',
      requiresAuth: false,
    }
  },
/////////////////Profile Route
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    //show the meta data tag
    meta:{
      title:'Profile',
      requiresAuth: true,
    }
  },
/////////////////Admin Route
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
    //show the meta data tag
    meta:{
      title:'Admin',
      requiresAuth: true,
      requiresAdmin: true
    }
  },

///////////////Create Post
  {
    path: "/create-post",
    name: "CreatePost",
    component: CreatePost,
    //show the meta data tag
    meta:{
      title:'Create Post',
      requiresAuth: true,
      requiresAdmin: true
    }
  },

  ///////////////Post Preview
  {
    path: "/post-preview",
    name: "BlogPreview",
    component: BlogPreview,
    //show the meta data tag
    meta:{
      title:'Preview Blog Post',
      requiresAuth: true,
      requiresAdmin: true
    }
  },

    ///////////////View Blog
    {
      path: "/view-blog/:blogid",
      name: "ViewBlog",
      component: ViewBlog,
      //show the meta data tag
      meta:{
        title:'View Blog Post',
        requiresAuth: false,
      }
    },

        ///////////////Edit Blog
        {
          path: "/edit-blog/:blogid",
          name: "EditBlog",
          component: EditBlog,
          //show the meta data tag
          meta:{
            title:'Edit Blog Post',
            requiresAuth: true,
            requiresAdmin: true
          }
        },

];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

//impliment the tag titles on Browser
router.beforeEach((to, from, next) => {
  document.title=`${to.meta.title} -- Han's Blog`;
  next();
});

//user cannot access to the admin function by typing the admin level page --- route guard
router.beforeEach(async (to, from, next) =>{
  let user = firebase.auth().currentUser;
  let admin = null;
  if (user) {
    let token = await user.getIdTokenResult();
    admin = token.claims.admin;
  }
  if (to.matched.some((res) => res.meta.requiresAuth)) {
    if (user) {
      if(to.matched.some((res) => res.meta.requiresAdmin)) {
        if (admin) {
          return next();
        }
         return next({ name: "Home" });
      }
      return next();
    }
    return next({name: "Home"})
  }
  return next();
});
export default router;
