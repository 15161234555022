<template>
  <div class="form-wrap">
    <form class="register">
                  <!--Login panel section-->
      <p class="login-register">
        Already Have an Account
        <router-link class="router-link" :to="{name:'Login'}">Login</router-link>
      </p>
      <h2>Create Your Account</h2>
      <div class="inputs">
                  <!--input form section for register-->
        <div class="input">
          <input type="text" placeholder="First Name" v-model="firstName" />
          <user class='icon'/>
        </div>

        <div class="input">
          <input type="text" placeholder="Last Name" v-model="lastName" />
          <user class='icon'/>
        </div>

        <div class="input">
          <input type="text" placeholder="User Name" v-model="username" />
          <user class='icon'/>
        </div>

        <div class="input">
          <input type="text" placeholder="Email" v-model="email" />
          <email class='icon'/>
        </div>

        <div class="input">
          <input type="text" placeholder="Password" v-model="password" />
          <password class='icon'/>
        </div>
      <!--show the error message returned from the server-->
      <div v-show='error' class="error">{{this.errorMsg}}</div>
      </div>
        <!--use @click.prevent to prevent the whole page refresh when the page request for the method-->
      <button @click.prevent="register">Sign up</button>
      <div class="angle"></div><!--design section, no content, used for styling-->
    </form>
     <div class="background"></div><!--design section, no content, used for styling-->
  </div>
</template>

<script>
import email from "../assets/Icons/envelope-regular.svg";
import password from "../assets/Icons/lock-alt-solid.svg";
import user from "../assets/Icons/user-alt-light.svg";
//import firebase
import firebase from "firebase/app"
import "firebase/auth"
import db from "../firebase/firebaseInit";

export default {
  name:"Register",
  components:{
    email,
    password,
    user
  },
  
  data(){
    return{
      firstName: "",
      lastName: "",
      username: "",
      email: "",
      password: "",
      error: null,
      errorMsg: "",
    };
  },

  methods: {
    async register(){
      if(
        this.email !== "" && 
        this.password !== "" &&
        this.firstName !== "" &&
        this.lastName !=="" &&
        this.username !== ""
      ){
        this.error = false;
        this.errorMsg = '';
        //////////////////////////////////firebase data section///////////////////////////////
        const firebaseAuth = await firebase.auth();
        const creatUser = await firebaseAuth.createUserWithEmailAndPassword(this.email, this.password);
        const result = await creatUser;
        const database = db.collection("users").doc(result.user.uid);
        await database.set({
          firstName: this.firstName,
          lastName: this.lastName,
          username: this.username,
          email: this.email,
          password: this.password
        });
        ////////////////////////////////////////////////////////////////////////////////////////
        
        //push user to mainpage
        this.$router.push({name: "Home"});
        return;
      }
      this.error = true;
      this.errorMsg = "Please fill out all fields";
      return;
    }
  }
}
</script>

<style lang="scss" scoped>
    .register{
      h2{
        max-width: 350px;
      }
    }
</style>