<template>
  <div class="reset-password">
    <!--Active the modal when click the send email, close the modal when click the button close-->
    <Modal v-if="modalActive" :modalMessage="modalMessage" v-on:close-modal="closeModal"/>
    <!--Active loading animation when waiting for the server response-->
    <Loading v-if="loading" />
    <div class="form-wrap">
      <form class="reset">
            <!--Login panel in Forgot Password section-->
          <p class="login-register">
            Already Have an Account
            <router-link class="router-link" :to="{name:'Login'}">Login</router-link>
          </p>
            <!--Form Content section-->
        <h2>Reset Your Password</h2>
        <p>Enter your email to reset it</p>
        <div class="inputs">
          <div class="input">
            <input type="text" placeholder="Email" v-model="email">
            <email class="icon"></email>
          </div>
        </div>
        <!--use @click.prevent to prevent the whole page refresh when the page request for the method-->
        <button @click.prevent="resetPassword">Reset</button>
        <div class="angle"></div><!--design section, no content, used for styling-->
      </form>
      <div class="background"></div><!--design section, no content, used for styling-->
    </div>
  </div>
</template>

<script>
import email from "../assets/Icons/envelope-regular.svg";
import Modal from "../components/Modal.vue"
import Loading from "../components/Loading.vue"
//import firebase
import firebase from "firebase/app"
import "firebase/auth"

export default {
    name: "ForgotPassword",
    data(){
      return {
        email: null,
        modalActive: false,
        modalMessage: "",
        loading: null,
      };
    
    },
  //register the view model component
    components: {
      email,
      Modal,
      Loading,
    },

    methods:{
      closeModal(){
        this.modalActive = !this.modalActive;
        this.email = ""
      },

      resetPassword(){
        this.loading = true;
        firebase.auth().sendPasswordResetEmail(this.email).then(() =>{
          this.modalMessage = "You will receive an email if you are using a valid email";
          this.loading = false;
          this.modalActive = true;
        })
        
        .catch((err)=>{
          this.modalMessage = err.message;
          this.loading = false;
          this.modalActive = true;
        });
      }
    }
}
</script>

<style lang="scss" scoped>
    .reset-password{
      position: relative;
      .form-wrap {
        .reset{
          h2 {
            margin-bottom: 8px;
          }

          p {
            text-align: center;
            margin-bottom: 32px;
          }

        }
      }
    }
</style>