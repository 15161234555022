import firebase from "firebase/app";
import "firebase/firestore";
//these codes are provided by firestore, do not do anything about it
//this project has been registered in my personal account, GA included
const firebaseConfig = {
    apiKey: "AIzaSyDRMDLre1qpjxxP-9L91pYu0HIqs8jeEL8",
    authDomain: "koribeinikihanspost.firebaseapp.com",
    projectId: "koribeinikihanspost",
    storageBucket: "koribeinikihanspost.appspot.com",
    messagingSenderId: "37168166666",
    appId: "1:37168166666:web:8a6f39527cc28992cfacf3",
    measurementId: "G-0VXBEECFNQ"
  };

const firebaseApp = firebase.initializeApp(firebaseConfig);
const timestamp = firebase.firestore.FieldValue.serverTimestamp;

export {timestamp}

export default firebaseApp.firestore();