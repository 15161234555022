import Vue from 'vue'
import Vuex from 'vuex'
//import firebase
import firebase from "firebase/app"
import "firebase/auth"
import db from "../firebase/firebaseInit";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    //show the sample blog cards in the Blog view
    // sampleBlogCards:[
    //   {blogTitle:"Blog Card 1", blogCoverPhoto:"stock-1", blogDate:"Feb 2, 2022"},
    //   {blogTitle:"Blog Card 2", blogCoverPhoto:"stock-2", blogDate:"Feb 2, 2022"},
    //   {blogTitle:"Blog Card 3", blogCoverPhoto:"stock-3", blogDate:"Feb 2, 2022"},
    //   {blogTitle:"Blog Card 4", blogCoverPhoto:"stock-4", blogDate:"Feb 2, 2022"},
    // ],
    //blog post uploaded on home page
    blogPosts: [],
    postLoaded: null,
    //Blog Dataset
    blogHTML: "Write your Blog Title here....",
    blogTitle: "",
    blogPhotoName: "",
    blogPhotoFileURL: null,
    blogPhotoPreview: null,
    editPost: null,
    //initialized the preset value into null
    user: null,
    profileAdmin: null,
    profileEmail: null,
    profileFirstName: null,
    profileLastName: null,
    profileUsername: null,
    profileId: null,
    profileInitials: null,
  },
//get the first 2 posts for feed and other 4 for cards
  getters: {
    blogPostsFeed(state){
      return state.blogPosts.slice(0,2);
    },

    blogPostsCards(state){
      return state.blogPosts.slice(2,6);
    },
  },

  mutations: {

    //get new blog post
    newBlogPost(state,payload){
      state.blogHTML = payload;
    },

    //update blog Title
    updateBlogTitle(state,payload){
      state.blogTitle = payload;
    },

    //Change the filenamen into the uploaded file name
    fileNameChange(state, payload){
      state.blogPhotoName = payload;
    },

    //create file url
    createFileURL(state, payload){
      state.blogPhotoFileURL = payload;
    },

    //close the preview
    openPhotoPreview(state){
      state.blogPhotoPreview = !state.blogPhotoPreview;
    },

    //get the payload value and update the state of editPost
    toggleEditPost (state,payload){
      state.editPost = payload;
    },

    
    //filter the blogpost that need to be deleted
    filterBlogPost(state, payload){
      state.blogPosts = state.blogPosts.filter((post) => post.blogID !== payload);
    },

    //update the current user
    updateUser(state, payload){
      state.user = payload;
    },

    //set the admin level for users
    setProfileAdmin(state, payload) {
    state.profileAdmin = payload;
    //console.log(state.profileAdmin)
   },

    //use the commit action to fill out the profile info above
    setProfileInfo(state, doc){
      state.profileId = doc.id;
      //get the dataset provided by the firebase 
      state.profileEmail = doc.data().email;//data().DATA provided by firebase
      state.profileFirstName = doc.data().firstName;
      state.profileLastName = doc.data().lastName;
      state.profileUsername = doc.data().username;
    },

    setProfileInitials(state){
      state.profileInitials = 
      //Initial the First Name and the Last Name by using Match() method; then join them together
        state.profileFirstName.match(/(\b\S)?/g).join("") + 
        state.profileLastName.match(/(\b\S)?/g).join(""); 
        //console.log(state.profileInitials);
    },
//use the payload to transfer the new user info to the server
    changeFirstName(state, payload){
      state.profileFirstName = payload;
    },

    changeLastName(state, payload){
      state.profileLastName = payload;
    },

    changeUsername(state, payload){
      state.profileUsername = payload;
    },

//set state for Editing the existing blog

    setBlogState(state, payload) {
      state.blogTitle = payload.blogTitle,
      state.blogHTML = payload.blogHTML,
      state.blogPhotoFileURL = payload.blogCoverPhoto,
      state.blogPhotoName = payload.blogCoverPhotoName
    },

  },
  actions: {
    async getCurrentUser({commit}, user){
      //reach to the collection and find the current user
      const dataBase = await db.collection('users').doc(firebase.auth().currentUser.uid);
      //get the current user
      const dbResult = await dataBase.get();
      //set the profile info
      commit("setProfileInfo", dbResult);
      commit("setProfileInitials");
      //whether the admin is true or false
      const token = await user.getIdTokenResult();
      const admin = await token.claims.admin;
      commit('setProfileAdmin', admin);
      //check the trun back result
      //console.log(dbResult);
    },

    async getPost( { state } ){
      //reference the database
      const dataBase = await db.collection('blogPosts').orderBy('date', 'desc');
      //fetch a array
      const dbResults = await dataBase.get();
      dbResults.forEach((doc) => {
          if(!state.blogPosts.some(post => post.blogID === doc.id)){
            const data = {
              blogID: doc.data().blogID,
              blogHTML: doc.data().blogHTML,
              blogCoverPhoto: doc.data().blogCoverPhoto,
              blogTitle: doc.data().blogTitle,
              blogDate: doc.data().date,
              blogCoverPhotoName: doc.data().blogCoverPhotoName,
            };
            state.blogPosts.push(data);
          }
      });
      state.postLoaded = true;
      //console.log(state.blogPosts.slice(2,6));
    },

    async deletePost({commit}, payload){
      const getPost = await db.collection('blogPosts').doc(payload);
      await getPost.delete();
      commit('filterBlogPost',payload)
    },

    async updatePost({commit,dispatch}, payload) {
      commit("filterBlogPost", payload);
      await dispatch("getPost");
    },

//this function is for updating the current user information provided by profile.vue
    async updateUserSettings({commit, state}){
      const dataBase = await db.collection('users').doc(state.profileId);
      await dataBase.update({
        firstName: state.profileFirstName,
        lastName: state.profileLastName,
        username: state.profileUsername,
      });
      //change the Initials displayed
      commit("setProfileInitials");
    },
  },
  modules: {
  }
})
