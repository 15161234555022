<template>

  <header>
      <nav class="container">

          <div class="branding">
              <router-link class="header" :to="{ name:'Home' }">
                  <img src="../assets/Logo.jpg" alt="">
                  Korobeiniki's Blog</router-link>
          </div>

                <!--Navigation Bar for Desktop-->

          <div class="nav-links">

              <ul v-show="!mobile">
                  <router-link class="link" :to="{ name: 'Home' }">Home</router-link>                 
                  <router-link class="link" :to="{ name: 'Blogs' }">Blogs</router-link> 
                  <router-link v-if="admin" class="link" :to="{ name: 'CreatePost' }">Create Post</router-link>
                  <router-link v-if="!user" class="link" :to="{ name: 'Login' }">Login/Register</router-link>
              </ul>
              <!--show the profile initial & the menu at the top right corner-->
            <div @click="toggleProfileMenu" class="profile" ref="profile" v-if="user">
                <span>{{ this.$store.state.profileInitials }}</span>
                <!--profile menu option and info, only show if the span circle is clicked-->
                <div class="profile-menu" v-show="profileMenu">
                            <!--menu information section-->
                    <div class="info">
                        <p class="initials">{{this.$store.state.profileInitials}}</p>
                        <div class="right">
                            <p>{{this.$store.state.profileFirstName}} {{this.$store.state.profileLastName}}</p>
                            <p>{{this.$store.state.profileUsername}}</p>
                            <p> {{this.$store.state.profileEmail}}</p>
                        </div>
                    </div>
                    <div class="options">
                            <!--menu options section-->
                            <router-link class='option' :to="{name:'Profile'}">
                                <userIcon class="icon" />
                                <p>Profile</p>
                            </router-link>

                            <router-link v-if="admin" class='option' :to="{name:'Admin'}">
                                <adminIcon class="icon" />
                                <p>Admin</p>
                            </router-link>

                            <div @click="signOut" class='option'>
                                <signoutIcon class="icon" />
                                <p>Sign Out</p>
                            </div>

                    </div>
                </div>
            </div>
          </div>

      </nav>

    <!--Navigation Bar for Mobile-->

      <menuIcon @click="toggleMobileNav" v-show="mobile" class='menu-icon'/>

      <transition name = "mobile-nav">
                
                <ul class = "mobile-nav" v-show="mobileNav">
                  <router-link class="link" :to="{name:'Profile'}" v-if="user"><span>{{ this.$store.state.profileInitials }}</span></router-link>
                  <router-link class="link" :to="{ name: 'Home' }">Home</router-link>                
                  <router-link class="link" :to="{ name: 'Blogs' }">Blogs</router-link>                 
                  <router-link  v-if="admin" class="link" :to="{ name: 'CreatePost' }">Create Post</router-link>                 
                  <router-link  v-if="!user" class="link" :to="{ name: 'Login' }">Login/Register</router-link>
                            <router-link class='link' :to="{name:'Profile'}" v-if="user">
                                <userIcon class="icon" />
                                <p>Profile</p>
                            </router-link>

                            <router-link v-if="admin" class='link' :to="{name:'Admin'}">
                                <adminIcon class="icon" />
                                <p>Admin</p>
                            </router-link>

                            <div @click="signOut" class='link' v-if="user">
                                <signoutIcon class="icon" />
                                <p>Sign Out</p>
                            </div>
                </ul>

      </transition>

  </header>
</template>

<script>
import menuIcon from '../assets/Icons/bars-regular.svg';
import userIcon from '../assets/Icons/user-alt-light.svg';
import adminIcon from '../assets/Icons/user-crown-light.svg';
import signoutIcon from '../assets/Icons/sign-out-alt-regular.svg';
//import firebase
import firebase from "firebase/app"
import "firebase/auth"

export default {
    //register the menu Icon
    name: 'navigation',
    components:{
        menuIcon,
        signoutIcon,
        adminIcon,
        userIcon,
    },
    //set the value for screen following
    data(){
        return{
        profileMenu: null,
        mobile:null,
        mobileNav:null,
        windowWidth:null,
        };
    },
    //add a listener for screen check
    created(){
        window.addEventListener('resize', this.checkScreen);
        this.checkScreen();
    },

    methods:{
    //justify the screen whether it is a mobile or desktop
        checkScreen(){
            this.windowWidth = window.innerWidth;
            if (this.windowWidth <=750){
                this.mobile = true;
                return;
            }else{
            this.mobile = false
            this.mobileNav = false;
            return;}
        },
    //toggle for the menu icon, use this function when @click the icon
        toggleMobileNav(){
            this.mobileNav = !this.mobileNav;
        },
    //toggle the profile menu, only the circle icon can react with the user
        toggleProfileMenu(e){
            if(e.target === this.$refs.profile){
            this.profileMenu = !this.profileMenu;
            }
        },
    //when click the sign out, use the firebase method and relocate the window location
        signOut(){
            firebase.auth().signOut();//use the firebase method
            window.location.reload();//relocate the window location
        }
    },

    computed: {
    //get the state of whether the page has a user
        user(){
            return this.$store.state.user;
        },
    //get the state of the admin
        admin(){
            return this.$store.state.profileAdmin;
        }
    }
}
</script>

<style lang="scss" scoped>
header{
    background-color: #fff;
    padding: 0 25px;
    box-shadow: 0 4px 6px -1px rgba($color: #000000, $alpha: 1.0), 0 2px 4px -1px rgba($color: #000000, $alpha: 0.06);
    z-index: 99;

    .link{
        font-weight: 500;
        padding: 0 8px;
        transition: .3s color ease;
        &:hover{
            color:#1eb8b8
        }
    }
}

nav{
    display: flex;
    padding: 25px 0;

    .branding{
        display:flex;
        align-items: center;

        .header{
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 24px;
            color:black;
            text-decoration: none;
            img{
                height: 48px;
                width: 48px;
            }
        }
    }
    .nav-links{
        position: relative;
        display: flex;
        flex:1;
        align-items: center;
        justify-content: flex-end;

        ul{
            margin-right: 32px;

            .link{
                margin-right: 32px;
            }

            .link:last-child{
                margin-right: 0;
            }
        }

        .profile{
            position: relative;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            color: #fff;
            background-color: #303030;
            @media (max-width: 750px){
                display: none;
            }

            span{
                pointer-events: none;
                //enable the user to click the whole profile circle
            }

            .profile-menu{
                position: absolute;
                top: 60px;
                right: 0;
                width: 250px;
                background-color: #303030;
                box-shadow: 0 4px 6px -1px rgba($color: #000000, $alpha: 1.0), 0 2px 4px -1px rgba($color: #000000, $alpha: 0.06);

                .info{
                    display: flex;
                    align-items: center;
                    padding: 15px;
                    border-bottom: 1px solid #fff;

                    .initials{
                        position: initial;
                        width: 40px;
                        height: 40px;
                        background-color: #fff;
                        color: #303030;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                    }

                    .right{
                        flex: 1;
                        margin-left: 24px;

                        p:nth-child(1){
                            font-size: 14px;
                        }

                        p:nth-child(2),
                        p:nth-child(3){
                            font-size: 12px;
                        }
                    }
                }

                .options {
                    padding: 15px;

                    .option{
                        text-decoration: none;
                        color: #fff;
                        display: flex;
                        align-items: center;
                        margin-bottom: 12px;

                        .icon{
                            width: 18px;
                            height: auto;
                        }

                        p {
                            font-size: 14px;
                            margin-left: 12px;
                        }

                        &:last-child{
                            margin-bottom: 0px;
                        }
                    }
                }
            }
        }
    }
}

.menu-icon{
    cursor:pointer;
    position:absolute;
    top: 32px;
    right: 25px;
    height: 25px;
    width:auto;
}

.mobile-nav {
    padding:20px;
    width:70%;
    max-width: 250px;
    display: flex;
    flex-direction: column;
    position:fixed;
    height:100%;
    background-color: #303030;
    top:0;
    left: 0;
    .link{
        display: flex;
        flex-direction: row;
        padding:15px 0;
        color:#fff;

        span{
            position: relative;
            cursor: pointer;
            display: flex;
            align-items: center;
            font-weight: 600;
            justify-content: center;
            width: 40px;
            height: 40px;
            border-radius: 50%;
            color: #303030;
            background-color: #fff;
        }

        .icon{
            width: 18px;
            height: auto;
            margin-right: 8px;
            }
    }
    
}
// below CSS are for the mobile navigation bar, the animition will be ease in and out when the toggle is actived/pointed
.mobile-nav-enter-active,
.mobile-nav-leave-active{
    transition: all 1s ease;
}

.mobile-nav-enter{
    transform: translateX(-250px);
}
.mobile-nav-enter-to{
    transform: translateX(0);
}
.mobile-nav-leave-to{
    transform: translateX(-250px);
}
///////////////////////////////////////////////////
</style>