<template>
  <div class="blog-card-wrap">
    <div class="blog-cards container">
      <div class="toggle-edit" v-if="admin">
        <span>Toggle Editing Post</span>
                <!--the check box will only appearing when the 'editPost' is true-->
        <input type="checkbox" v-model="editPost" />
      </div>
      <!--import the same view model and register it as BlogCard-->
      <BlogCard :post="post" v-for="(post, index) in blogPosts" :key="index"/>
    </div>
  </div>
</template>

<script>
import BlogCard from "../components/BlogCard.vue"
export default {
    name: 'blogs',
    components: { BlogCard },
    computed: {

      //return the pre-settled sample blog cards
      // sampleBlogCards(){
      //   return this.$store.state.sampleBlogCards;
      // },

        admin(){
            return this.$store.state.profileAdmin;
        },

      blogPosts(){
        return this.$store.state.blogPosts;
      },

      //toggle function of 'editPost'
      editPost: {
        //get the binary value from the check box(T/F)
        get() {
          return this.$store.state.editPost;
        },
        //set the value into the 'payload' and transport it to store.js --- script --- ToggleEditPost
        set(payload){
          this.$store.commit("toggleEditPost", payload);
        },
      },
    },
    //set the toggleEditPost value to false when user leave the view. 
    //Therefore there will be no editing option shown on the main page.
    beforeDestroy(){
    this.$store.commit("toggleEditPost", false);
  }
};
</script>

<style lang="scss" scoped>

    .blog-cards{
      position: relative;

      .toggle-edit{
        display: flex;
        align-items: center;
        position: absolute;
        top: -70px;
        right: 0;
      }

      span{
        margin-right: 16px;
      }

      input[type="checkbox"]{
      position: relative;
      border: none;
      -webkit-appearance: none;
      background: #fff;
      outline: none;
      width: 80px;
      height: 30px;
      border-radius: 20px;
      box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.6);
    }

    input[type="checkbox"]::before{
      content: "";
      position: absolute;
      width: 30px;
      height: 30px;
      border-radius: 20px;
      top: 0;
      left: 0;
      background: #303030;
      transform: scale(1.1);
      transition: .75s ease all;
      box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.6);
    }

    input:checked[type="checkbox"]::before{
      background: #fff;
      left: 52px;
    }

    }

    
</style>